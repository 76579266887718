import React, { Suspense } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { isSSR, actualCountry } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"
import CardResource from "../components/ProductDetail/language.json"

const ProductDetail = React.lazy(() => import("../components/ProductDetail"))
const component = Resource[process.env.GATSBY_SITE_VERSION]
const adicionalComponent = CardResource[process.env.GATSBY_SITE_VERSION]

export default () => {
  const data = useStaticQuery(graphql`
    query {
      pymeMx: file(
        relativePath: { eq: "mx/landings/pyme-credito-iban-online-mexico.jpg" }
      ) {
        ...customFragmentImageFluid
      }
      pymeDo: file(
        relativePath: { eq: "do/landings/prestamo-para-pyme-iban-online.jpg" }
      ) {
        ...customFragmentImageFluid
      }
    }
  `)

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.pymeCredit.SEO.title}
            keywords={component.pymeCredit.SEO.keywords}
            description={component.pymeCredit.SEO.description}
          />
          <Layout>
            <ProductDetail
              pageData={{
                alt: component.pymeCredit.pageData.alt,
                credit: component.pymeCredit.pageData.credit,
                minAmount: component.pymeCredit.minAmount,
                minLoanDurationInMonths:
                  component.pymeCredit.minLoanDurationInMonths,
                titleProduct: component.pymeCredit.pageData.titleProduct,
                textProduct: component.pymeCredit.pageData.textProduct,
                href: component.pymeCredit.pageData.href,
                textBenefits: component.pymeCredit.pageData.textBenefits,
                image:
                  actualCountry === "do"
                    ? data.pymeDo.childImageSharp.fluid
                    : data.pymeMx.childImageSharp.fluid,
                cardDataUp: adicionalComponent.cardPYMESCredit,
                applicationType: component.pymeCredit.pageData.applicationType,
                form: component.pymeCredit.form.inputs,
                cardDataHeader: component.pymeCredit.pageData.cardDataHeader,
              }}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
